@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "fonts/roobert";
@import "fonts/untitled_sans";

@import "mixins/utils";

@import "general/colors";
@import "general/buttons";
@import "general/form";
@import "general/select";

@import "components/header";
@import "components/footer";
@import "components/multirange";
@import "components/paginator";
@import "components/project_description";
@import "components/shared/show_more_less_component";
@import "components/shared/lead_modal";
@import "components/shared/share_modal";
@import "components/shared/customer_modal";
@import "components/shared/project_map";
@import "components/shared/property_modal";
@import "components/shared/project_card";
@import "components/shared/search";
@import "components/shared/save_search_modal";
@import "components/shared/set_password";
@import "components/shared/profile_modal";
@import "components/shared/edit_destroy_search_modal";

@import "pages/developers";
@import "pages/projects";
@import "pages/project";
@import "pages/home";
@import "pages/news";
@import "pages/contact";
@import "pages/profile";
@import "pages/collaborators";
@import "pages/profile";
@import "pages/users";
@import "pages/landing_page";
@import "pages/offers";

@layer components {
  .no-modal-scrollbar {
    @screen lg {
      // prevent the y scrollbar from repositioning the header
      // when a modal is open
      // https://stackoverflow.com/a/39289453
      @apply overflow-x-hidden;
      margin-right: calc(-1 * (100vw - 100%));
    }
  }
}

body {
  @apply no-modal-scrollbar;

  &.modal-open-xs {
    @apply overflow-hidden;

    @screen md {
      @apply overflow-visible;
    }
  }

  &.modal-open {
    @apply overflow-hidden;
  }

  * {
    &:focus {
      @apply outline-none;
    }
  }
}

[x-cloak] { display: none !important; }
