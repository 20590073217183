#project-summary {
  @apply flex flex-col mx-auto my-6 lg:mt-10 px-4;

  max-width: 90rem;

  @screen lg {
    @apply flex-row px-12;
  }

  .project-summary-title {
    @apply w-full;

    @screen lg {
      @apply pr-4;

      width: 40%;
    }

    .location {
      @apply flex items-center;

      img {
        @apply w-4 h-4 mr-2;
      }

      span {
        @apply mr-6 text-base font-roobert;

        color: $black;
      }

      .property-card-label {
        @apply font-untitledSans uppercase pt-1 px-2 rounded-sm;

        padding-bottom: .125rem;
        font-size: .625rem;

        @screen lg {
          @apply text-xs;
        }

        &.sold {
          background: $silver;
          color: $black;
        }

        &.under-construction {
          background: $scienceBlue;
          color: $white;
        }
      }
    }

    .name {
      @apply text-3.5xl lg:text-5xl font-roobert mb-4 lg:mb-6;

      color: $black;
    }

    .actions {
      @apply flex mb-4 lg:mb-6;

      .action {
        @apply p-1 flex items-center cursor-pointer rounded-sm;

        &:nth-of-type(1) {
          @apply mr-4;
        }

        img {
          @apply h-4 w-4 mr-1;
        }

        span {
          @apply text-sm font-roobert;

          color: $black;
        }
      }
    }
  }

  .project-summary-description {
    @apply w-full;

    @screen lg {
      width: 60%;
    }

    .headline {
      @apply text-lg font-untitledSans mb-8;

      color: $black;

      @screen lg {
        @apply text-2xl;
      }
    }

    .params {
      @apply flex flex-col lg:flex-row mb-0 lg:mb-8;

      .param {
        @apply flex flex-col mb-6 lg:mb-0;

        flex: 1;

        &:nth-of-type(1) {
          @apply mr-2;
        }

        @screen lg {
          flex: inherit;

          &:nth-of-type(1) {
            @apply mr-12;
          }
        }

        .param-label {
          @apply text-xs font-untitledSans uppercase mb-2;

          color: $doveGray;
        }

        .param-value {
          @apply text-2xl font-untitledSans;

          color: $black;
        }
      }
    }

    .actions {
      @apply flex flex-col;

      @screen lg {
        @apply flex-row;
      }

      .btn {
        &:not(:last-child) {
          @apply mb-4;

          @screen lg {
            @apply mr-6 mb-0;
          }
        }
      }
    }
  }
}

#map.project-map-section {
  max-width: 90rem;
}

#units-list {
  max-width: 90rem;

  h2 {
    font-size: 2rem;
  }

  .units-list-desktop {
    @apply w-full hidden;

    @screen lg {
      @apply table;
    }

    thead {
      tr {
        th {
          @apply p-6 text-left text-xs font-untitledSans-medium uppercase cursor-pointer;

          border-bottom: 1px solid $mercury;

          .header-column {
            @apply flex flex-row items-center;

            img {
              @apply ml-1 relative;

              top: -1px;
            }

            &.reverse {
              img {
                rotate: 180deg;
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        td {
          @apply p-6 text-left text-base font-untitledSans;

          border-bottom: 1px solid $mercury;

          &.price-column {
            .price-label {
              @apply uppercase text-xs font-untitledSans-medium rounded-sm;

              background: $ebb;
              color: $black;
              padding: .25rem .375rem;
            }
          }
        }
      }
    }
  }

  .units-list-mobile {
    @apply block;

    @screen md {
      @apply flex flex-row flex-wrap;

      .unit-info {
        width: calc(50% - 8px);

        &:nth-of-type(2n+1) {
          @apply mr-4;
        }
      }
    }

    @screen lg {
      @apply hidden;
    }

    .unit-info {
      @apply p-6 mb-4;

      background: $alabaster;

      h3 {
        @apply text-xl font-roobert-medium mb-4;

        color: $black;
      }

      .unit-info-block {
        @apply flex flex-row mb-4;

        &:nth-last-of-type(1) {
          @apply mb-0;
        }

        .unit-info-item {
          @apply flex flex-col;

          .unit-info-item-label {
            @apply uppercase font-untitledSans mb-1;

            font-size: .625rem;
          }

          .unit-info-item-value {
            @apply text-sm font-untitledSans;
          }

          .price-label {
            @apply uppercase font-untitledSans-medium rounded-sm;

            background: $silver;
            color: $black;
            font-size: .625rem;
            padding: .25rem .375rem;
          }
        }
      }

      &.sold {
        background: $mercury;

        .unit-info-block {
          .unit-info-item-label {
            color: $gray;
          }

          .unit-info-item-value {
            color: $doveGray;
          }
        }
      }
    }
  }

  #show-more-properties-button {
    @apply py-3 w-full;

    @screen lg {
      @apply mt-10 mx-auto;

      width: auto;
    }
  }

  #property-picker-container {
    // needed because of the parent's padding
    margin-left: -16px;
    width: calc(100% + 32px);

    @screen md {
      @apply w-full pb-10;

      margin-left: 0px;
      max-width: 84rem;
    }
  }

  // TODO: remove when we update tailwind to 3.x
  .underline-offset-4 {
    text-underline-offset: 4px;
  }
}

#developers-details {
  @apply flex flex-col mx-auto px-4;

  max-width: 90rem;

  @screen lg {
    @apply flex-row px-12;
  }

  .developers-details-header {
    flex: 1;

    h2 {
      @apply font-roobert;

      font-size: 1.75rem;
      color: $black;
    }
  }

  .brokers-list {
    @apply flex flex-col flex-wrap w-full;

    @screen md {
      @apply flex-row justify-between;
    }

    @screen lg {
      @apply flex-row-reverse;

      width: 41.5rem;
    }

    .broker-info {
      @apply mb-10;

      @screen md {
        width: calc(50vw - 28px);
      }

      @screen lg {
        width: 20rem;
      }

      .broker-profile-image {
        @apply mb-6 object-cover;

        width: calc(100vw - 32px);
        height: calc(100vw - 32px);

        @screen md {
          width: calc(50vw - 28px);
          height: calc(50vw - 28px);
        }

        @screen lg {
          width: 20rem;
          height: 20rem;
        }
      }

      .broker-contact {
        @apply text-lg font-untitledSans mb-6;

        color: $black;
      }

      .broker-contact {
        @apply mb-0;
      }
    }
  }
}

.properties-list-filters {
  @apply flex flex-row;

  .left-side {
    @apply flex flex-row;

    @screen md {
      & > div {
        @apply mr-4;
      }
    }
  }

  .properties-sort-dropdown, .properties-stage-dropdown {
    @apply mb-3;

    @screen lg {
      @apply hidden;
    }

    &.expanded {
      .dropdown {
        .dropdown-value {
          img {
            rotate: 180deg;
          }
        }

        .dropdown-list {
          @apply block;
        }
      }
    }

    .dropdown {
      @apply relative;

      .dropdown-value {
        @apply px-2 lg:px-4 py-2;

        span {
          @apply text-base font-roobert mr-1.5 md:m-2.5;
        }

        img {
          @apply w-4 h-4;
        }
      }

      .dropdown-list {
        @apply hidden absolute w-full left-0 rounded-sm;

        background: $white;
        z-index: 1;
        border: 1px solid $coldTurkey;
      }
    }
  }

  .properties-sort-dropdown {
    .dropdown {
      .dropdown-list {
        @apply hidden;

        &.opened {
          @apply hidden;
        }

        @screen md {
          &.opened {
            @apply block w-56;
          }
        }

        ul {
          li {
            @apply text-base font-roobert px-4 py-2 cursor-pointer flex;

            color: $black;
          }
        }
      }
    }
  }

  .properties-stage-dropdown {
    @screen lg {
      @apply block;
    }

    &.expanded {
      .dropdown {
        .dropdown-value {
          background: $lividBrown;

          span {
            color: $white;
          }
        }
      }
    }

    .dropdown {
      button {
        .selected-stages-count {
          @apply inline-block w-5 h-5 flex items-center justify-center text-xs;

          background: $lividBrown;
          color: $white;
          border-radius: 50%;
        }
      }

      .dropdown-list {
        @apply hidden;

        &.opened {
          @apply hidden;
        }

        @screen md {
          width: 28.75rem;

          &.opened {
            @apply block;
          }
        }

        .stages-list {
          @apply p-6 pb-0 flex flex-row flex-wrap justify-between;

          border-bottom: 1px solid $mercury;

          .stage-link {
            @apply px-5 py-4 flex flex-row items-center cursor-pointer w-full mb-4;

            background: $alabaster;

            @screen md {
              width: calc(50% - 8px);
            }

            .stage-link-check {
              @apply w-4 h-4 rounded-sm flex items-center justify-center;

              border: 1.5px solid $black;

              span {
                @apply w-2 h-2;

                img {
                  @apply w-full h-full;
                }
              }
            }

            .stage-link-name {
              @apply text-base font-roobert ml-2;

              color: $black;
            }
          }
        }

        .stages-actions {
          @apply flex flex-row justify-between py-4 px-6;
        }
      }
    }
  }
}

.properties-list-filters {
  @apply flex flex-row justify-between;
}

.properties-sold-filter {
  @apply flex flex-col items-center mb-6 cursor-pointer;

  height: 2.5rem;

  .toggler {
    @apply w-12 h-6 relative flex flex-row items-center;

    .toggler-background {
      @apply w-full h-3;

      border-radius: .5625rem;
    }

    .toggler-switch {
      @apply absolute h-6 w-6 left-0;

      background: $gray;
      border-radius: 50%;
    }
  }

  p {
    @apply text-base font-roobert;
  }

  &:not(.toggled) .toggler .toggler-background {
    background: $silver !important;
  }

  &.toggled {
    .toggler {
      .toggler-background {
        background: $blueRibbon;
      }

      .toggler-switch {
        @apply right-0;

        background: $stratos;
        left: auto;
      }
    }
  }

  @screen lg {
    @apply flex-row items-center;

    p {
      @apply ml-4;
    }
  }
}

.project-info {
  @apply flex flex-col mx-auto px-4 relative;

  max-width: 90rem;
  padding-bottom: 4rem;
  margin-bottom: 4rem;

  &:after {
    @apply absolute bottom-0 mx-auto;

    content: '';
    width: calc(100% - 32px);
    height: 1px;
    max-width: 90rem;
    background: $mercury;

    @screen lg {
      width: calc(100% - 96px);
    }
  }

  @screen lg {
    @apply flex-row px-12;

    padding-bottom: 5rem;
    margin-bottom: 5rem;
  }

  #project-info-box {
    @apply w-full;

    @screen lg {
      width: 40%;
    }

    #project-params {
      @apply flex flex-wrap mb-6;

      background-color: $alabaster;
      padding: 1rem 1.875rem 0 1.875rem;

      @screen lg {
        @apply flex-row w-2/3 rounded-lg mr-6;

        padding: 1.875rem;
      }

      .project-params-row {
        @apply flex flex-wrap w-full;

        &:nth-of-type(1) {
          .project-params-column {
            @apply mb-1;
          }
        }

        .project-params-column {
          @apply flex-col mb-6;

          width: 50%;

          &:nth-of-type(2n) {
            @apply mr-0;
          }

          p {
            @apply font-untitledSans;

            @screen lg {
              line-height: 145%;
            }

            &.param-label {
              @apply text-sm uppercase;

              color: $doveGray;

              @screen lg {
                margin-bottom: .1875rem;
              }
            }

            &.param-value {
              @apply text-lg overflow-hidden;

              flex: 1 1;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }

  #agency-logo-box {
    @apply mb-6;

    @screen lg {
      @apply w-2/3;
    }

    .logo {
      max-width: 16rem;
      max-height: 8rem;
    }
  }

  #project-description {
    @apply w-full font-untitledSans text-lg;

    @screen lg {
      width: 60%;
    }

    .shown-less {
      @apply max-h-60;

      @screen lg {
        @apply h-60;
      }
    }

    .project-developer-label {
      @apply mb-3 font-untitledSans text-xl;
    }

    #developer-logo-container {
      @apply flex flex-col;

      @screen lg {
        @apply flex-row;
      }

      .logo {
        max-width: 16rem;
        max-height: 8rem;

        @screen lg {
          margin: inherit
        }
      }
    }
  }

  .logo-container {
    @apply px-10 py-4 border-2;

    border-color: $alabaster;

    .logo {
      @apply m-auto
    }
  }

  #project-developer {
    margin-right: 8.625rem;
  }

  .underline-offset-4 {
    text-underline-offset: 4px;
  }
}

#properties-stages-filter-modal {
  .stage-link {
    width: calc(50vw - 28px);

    .stage-link-check {
      border: 1.5px solid $black;
    }
  }
}

#full-size-gallery-modal-container {
  .carousel {
    .flickity-viewport {
      width: 100% !important;
      height: 100% !important;

      .flickity-slider {
        @apply w-full h-full;
      }
    }

    .blurred {
      filter: blur(8px);
    }

    .flickity-button {
      @apply hidden;

      @screen md {
        @apply block absolute h-full top-0 w-8;
      }

      @screen lg {
        @apply w-12;
      }

      svg {
        @apply block w-8 h-8 rounded-sm border border-cold-turkey text-cold-turkey fill-current transform rotate-180;

        padding: .5rem;

        @screen lg {
          @apply w-12 h-12;

          padding: .875rem;
        }
      }

      &.previous {
        @apply left-2;

        @screen lg {
          @apply left-3;
        }
      }

      &.next {
        @apply right-2;

        @screen lg {
          @apply right-3;
        }
      }

      &:disabled {
        svg {
          @apply opacity-50;
        }
      }
    }
  }
}

#scroll-view-block {
  max-width: 55.5rem;

  img {
    max-height: 37.5rem;
  }
}
