.footer {
  @apply flex justify-center pt-10 pb-0;

  background: $softPeach;
  border-bottom: 1px solid $ebb;

  @screen lg {
    @apply py-12;
  }

  .footer-body {
    @apply mx-auto flex flex-col w-full px-6;

    max-width: 90rem;

    @screen lg {
      @apply flex-row justify-between;
    }
  }

  .footer-links {
    @apply flex flex-col mt-12;

    @screen lg {
      @apply flex-row mt-0;
    }

    .footer-col {
      @apply w-full mb-6;

      border-bottom: 1px solid $ebb;

      @screen lg {
        @apply mb-0;

        width: 15.625rem;
        border: none;
      }

      &:nth-last-of-type(1) {
        border: none;
      }
    }

    .footer-links-title {
      @apply mb-4 uppercase text-xs font-untitledSans;

      color: $black;
    }

    .footer-link {
      ul {
        @apply p-0;

        list-style: none;

        li {
          @apply mb-4;

          &:nth-last-of-type(1) {
            @apply mb-6;

            @screen lg {
              @apply mb-4;
            }
          }

          a {
            @apply inline-block text-base font-untitledSans;

            color: $black;
          }
        }
      }
    }
  }
}

.post-footer {
  @apply flex justify-center py-4;

  background: $softPeach;

  .post-footer-body {
    @apply mx-auto px-6 flex w-full flex-col-reverse;

    max-width: 90rem;

    @screen lg {
      @apply flex-row justify-between;
    }

    .site-info {
      @apply flex flex-col-reverse;

      @screen lg {
        @apply flex-row;
      }

      p {
        @apply text-sm font-untitledSans flex items-center;
      }

      .site-info-links {
        @apply text-sm font-untitledSans flex items-center flex-wrap mb-2;

        @screen lg {
          @apply mb-0;
        }

        a {
          &:nth-of-type(1) {
            @apply mr-6;
          }
        }
      }
    }
  }

  .footer-links {
    @apply mb-5 flex flex-row justify-between flex-wrap;

    @screen lg {
      @apply mb-0;
    }

    .item-group {
      @apply flex flex-row mr-4;

      .item {
        @apply mr-4 text-base font-untitledSans flex items-center;

        &:nth-last-of-type(1) {
          @apply mr-0;
        }

        img {
          @apply w-6 h-6;
        }

        span {
          margin-top: .125rem;
        }
      }
    }
  }
}
