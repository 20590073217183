#edit-destroy-search-modal {
  #edit-search-modal {
    &.lg-modal-w {
      @screen lg {
        width: 32.5rem;
      }
    }

    .toggle {
      @apply bg-melrose;

      border-radius: .5625rem;

      &:after {
        @apply absolute w-6 h-6 rounded-full bg-stratos left-0;

        content: '';
        top: -.375rem;
      }

      &.active {
        @apply bg-blue-ribbon;

        &:after {
          @apply left-auto right-0;
        }
      }
    }
  }
}
