@layer components {
  .imported-unit-header {
    font-size: inherit;
    font-weight: 700;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .imported-unit-subheader {
    font-size: 1.125rem;
    font-weight: 700;
    padding-top: 1rem;
  }

  .imported-unit-paragraph {
    font-size: 1.125rem;
  }
}
