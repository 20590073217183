#map-page {
  height: calc(100vh - 73px);

  .drawing-active {
    @apply text-white bg-livid-brown;
  }

  .projects-list {
    width: 40rem;
    padding-top: 85px;

    #filters {
      // a workaround to force filters container to span 100% parent widths;
      // it's the parent's width minus left and right padding;
      // `min-width` to allow it increase the width in the full list view
      min-width: 37rem;
    }

    @screen md {
      @apply pt-4;
    }

    .mobile-filters {
      box-shadow: 0 .75rem 1.5rem rgba(0, 0, 0, 0.05);
      top: 73px;

      @screen md {
        top: 77px;
      }
    }

    &.list-view-only {
      @apply w-full;

      $project-card-w: 28rem;

      .md-project-card-w {
        @screen md {
          width: $project-card-w;
        }
      }

      // below does the same as `flex flex-wrap` but can
      // be used with the gap property which has been available with grid layouts in Safari since version 12
      // where support for gap in flexbox layouts was added in version 14.1.
      .md-list-view-grid-template-columns {
        @screen md {
          grid-template-columns: repeat(auto-fit, minmax($project-card-w, $project-card-w));
        }
      }
    }

    &.android {
      .mobile-filters {
        span {
          @apply text-xs;
        }
      }
    }
  }

  .properties-map-container {
    height: 100%;

    &.android {
      .map-mobile-filters {
        span {
          @apply text-xs;
        }
      }
    }

    #properties-map {
      @apply h-full;
    }

    .map-cluster-marker {
      box-shadow: 0 .75rem 1.5rem rgba(0, 0, 0, 0.05);

      @-moz-document url-prefix() {
        padding-top: .125rem;
      }

      &.active {
        @apply bg-livid-brown text-white border-white;
      }
    }

    .map-listing-marker {
      .overlay-marker {
        padding-left: 2.75rem;
        border-radius: 2.625rem;
        transform: translateY(calc(-100% - 6px)) translateX(-50%);

        @screen lg {
          &:hover .overlay-pin {
            border-top: solid 8px $lividBrown;
          }
        }

        .overlay-pin {
          border-top: solid 8px white;
          border-left: solid 7px transparent;
          border-right: solid 7px transparent;
          border-bottom: solid 8px transparent;
        }

        .marker-icon {
          @apply text-xl;
        }

        &:after {
          @apply absolute w-5 h-5 left-3.5;

          content: '';
          background-image: url(../../assets/images/v2/icons/eiendom_black.svg);
        }
      }

      &.active {
        .overlay-marker {
          @apply bg-livid-brown;

          .marker-label {
            div {
              @apply text-white;
            }
          }

          .overlay-pin {
            border-top: solid 8px $lividBrown;
          }

          &:after {
            background-image: url(../../assets/images/v2/icons/eiendom_white.svg);
          }
        }
      }
    }

    #map-cluster-marker-template, #map-listing-marker-template {
      @apply hidden;
    }

    .overlay-details {
      @apply h-auto bg-background-10;

      width: 20.25rem;

      .project-card-content {
        .image-block {
          height: 11.3125rem;
        }

        .info-block {
          @apply px-3 pb-2;
        }
      }
    }
  }

  #projects-slider-mobile {
    filter: drop-shadow(0 .75rem 1.5rem rgba(0, 0, 0, 0.1));

    .card_label {
      @apply left-2;

      top: -.675rem;
    }

    .favourite-button {
      @apply hidden;

      top: .6rem;
      right: .6rem;

      &.favourite-button-mobile-carousel {
        @apply flex;
      }
    }

    .image-block {
      @apply h-24;

      width: 6.875rem;
    }

    .info-block {
      @apply justify-start pl-4 pt-3;

      width: calc(100% - 6.875rem);

      .project-title {
        @apply text-base mb-3 leading-5;
      }

      .project-card-info {
        @apply hidden;
      }

      .project-card-stats {
        img {
          @apply hidden;
        }

        .project-card-stats-value {
          @apply text-xs mt-0;
        }
      }
    }
  }

  #list-sorting {
    @screen lg {
      .sorting-group:hover .sorting-label {
        @apply border-b border-black-100;
      }

      .sorting-group:hover .sorting-icon {
        @apply border-b border-transparent;
      }
    }
  }

  #filters, #filters-mobile {
    .filter-header {
      .filter-header-count {
        padding: .125rem .9rem .125rem .35rem;
      }

      &.opened {
        @apply bg-livid-brown border-livid-brown;

        .filter-header-text {
          @apply text-white;
        }

        .filter-header-count {
          @apply bg-ebb text-black-100;
        }
      }
    }

    .range-values-box {
      .range-values-block {
        @screen md {
          width: 46%;
        }
      }
    }

    .filter-values {
      @screen md {
        width: 30rem;
        filter: drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.1));
      }

      .filter-value-checkbox {
        @apply w-1/2 pr-2;

        @screen md {
          @apply mr-4 pl-5;

          width: 13rem;

          &:nth-of-type(even) {
            @apply mr-0;
          }
        }

        input {
          @apply hidden;

          & + label {
            &::before {
              @apply absolute border border-black-100 rounded-sm;
              @apply w-4 h-4;

              content: '';
              top: .125rem;
              border-width: 1.5px;
            }

            &::after {
              @apply hidden;

              content: '';
            }
          }

          &:checked {
            & + label {
              &::after {
                @apply block absolute w-2 h-2;

                content: '';
                background: url(../../assets/images/v2/icons/mdi_check.svg);
                background-size: 100% 100%;
                background-position: center;
                background-repeat: no-repeat;
                top: .375rem;
                left: .25rem;
              }
            }
          }
        }
      }
    }
  }
}

.full-header {
  #navigation .navigation-container {
    @screen 2xl {
      @apply max-w-none;
    }
  }
}

.overscroll-none {
  overscroll-behavior: none;
  overflow: hidden;
  overscroll-behavior-y: contain;

  @apply fixed top-0 left-0 bottom-0 right-0 m-0;

  @media(max-width: calc(theme('screens.lg') - 1px)) {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }

  #map-page {
    @apply fixed lg:absolute h-full left-0 bottom-0 right-0 m-0 overflow-auto;

    -webkit-overflow-scrolling: touch;
    padding-top: 73px;
  }
}

#saved-search-modal {
  width: 21.375rem;
}

// TODO: save-search-modal is now available site-wide so I guess the below should be extracted to a shared
// scss file (like buttons.scss)
#saved-search-modal, #save-search-modal {
  .checkbox-box {
    input {
      & + label {
        &::before {
          @apply absolute border border-black-100 rounded-sm;
          @apply w-4 h-4;

          content: '';
          top: .125rem;
          border-width: 1.5px;
        }

        &::after {
          @apply hidden;

          content: '';
        }
      }

      &:checked {
        & + label {
          &::after {
            @apply block absolute w-2 h-2;

            content: '';
            background: url(../../assets/images/v2/icons/mdi_check.svg);
            background-size: 100% 100%;
            background-position: center;
            background-repeat: no-repeat;
            top: .375rem;
            left: .25rem;
          }
        }
      }
    }
  }
}
