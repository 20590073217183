#news {
  $article-card-w: 27rem;

  .lg-article-card-w {
    @screen lg {
      width: $article-card-w;
    }
  }

  .lg-list-view-grid-template-columns {
    @screen lg {
      grid-template-columns: repeat(auto-fit, minmax($article-card-w, $article-card-w));
    }
  }

  .article-card-content {
    @screen lg {
      &:not(.for_map):hover {
        .article-title {
          @apply text-blue-ribbon;
        }
      }
    }

    .article-image {
      height: 14.125rem;

      @screen md {
        height: 31rem;
      }

      @screen lg {
        height: 15.1875rem;
      }

      .card_label {
        padding-bottom: .125rem;
      }
    }
  }
}
