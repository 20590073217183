#for-developers {
  .w-subtitle-lg {
    @screen md {
      width: 41.25rem;
    }
  }

  .h-main-image {
    @screen md {
      height: 37.5rem;
    }
  }

  .w-max-secondary-image {
    max-width: 34.125rem;
  }
  .h-secondary-image {
    height: 19.25rem;
  }

  .h-visibility-section {
    height: 35rem;
  }

  .w-section {

    @screen md {
      width: 42rem;
    }
  }

  .rounded-boost-icon {
    border-radius: 30rem;
  }
}
