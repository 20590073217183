#delete-user-modal {
  .delete-user {
    width: 31.25rem;
  }
}

#profile-edit-modal {
  .profile-edit {
    @screen md {
      width: 36.25rem;
      max-height: calc(100vh - 11.25rem);
    }
  }
}
