.project-card-content {
  @screen lg {
    &:not(.for_map) {
      &:not(.custom_styles) {
        &:hover {
          .image-block > img {
            transform: scale(1.05);
          }

          .project-title {
            @apply text-blue-ribbon;
          }
        }
      }
    }
  }

  .favourite-button {
    filter: drop-shadow(0 .75rem 1.5rem rgba(0, 0, 0, 0.1));

    &:hover {
      filter: none;
    }
  }

  .image-block {
    height: 14.125rem;

    img {
      max-width: 100%;
      transition: 0.5s all ease-in-out;
    }

    @screen lg {
      height: 15.1875rem;
    }

    .card_label {
      padding-bottom: .125rem;
    }
  }

  .divider {
    span {
      top: .625rem;
    }
  }

  .param-field {
    p {
      margin-top: .125rem;
    }
  }

  &.horizontal {
    @apply flex-row;

    .image-block {
      max-width: 54%;
      width: 17.5rem;
      height: 9.875rem;
    }

    .info-block {
      @apply flex-auto pl-6 pt-0 justify-between;

      width: 46%;

      .project-card-info {
        @apply flex-col mb-0;

        .location {
          @apply mb-1;
        }

        .divider {
          @apply hidden;
        }
      }

      .project-card-stats {
        @apply flex-col;
      }
    }
  }

  &.md-horizontal {
    @screen md {
      @apply flex-row;

      .image-block {
        max-width: 54%;
        width: 17.5rem;
        height: 9.875rem;
      }

      .info-block {
        @apply flex-auto pl-6 pt-0 justify-between;

        width: 46%;

        .project-card-info {
          @apply flex-col mb-0;

          .location {
            @apply mb-1;
          }

          .divider {
            @apply hidden;
          }
        }

        .project-card-stats {
          @apply flex-col;
        }
      }
    }
  }

  &.for_map {
    .image_block {
      height: 9.4375rem;
    }

    .project-card-stats {
      @apply flex-col;
    }

    .project-card-info {
      @apply mb-1;
    }
  }
}

.project-card-loading-content {
  .left-block {
    width: 17.5rem;
    height: 9.875rem;
    background: linear-gradient(90deg, rgba(249, 243, 243, 0.5) 0%, #F9F3F3 51.04%);

    .left-block-title {
      width: 7.375rem;
    }

    .left-block-round {
      filter: drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.1));
    }
  }

  .titles {
    margin-bottom: 2.1875rem;

    .project-title {
      background: linear-gradient(90deg, rgba(249, 243, 243, 0.5) 0%, #F9F3F3 51.04%);

      &:nth-of-type(1) {
        width: 7.5625rem;
        margin-bottom: .4375rem;
      }

      &:nth-of-type(2) {
        width: 3.875rem;
        height: 1.125rem;
      }

      &:nth-of-type(3) {
        padding-right: 1.375rem;
        height: 1.125rem;
      }
    }
  }

  .param-field {
    .param-field-left {
      width: 1.125rem;
      height: 1.125rem;
      background: linear-gradient(90deg, rgba(249, 243, 243, 0.5) 0%, #F9F3F3 51.04%);
    }

    .param-field-right {
      width: 3.625rem;
      height: 1.125rem;
      background: linear-gradient(90deg, rgba(249, 243, 243, 0.5) 0%, #F9F3F3 51.04%);
    }

    &:nth-of-type(2) {
      .param-field-right {
        width: 4.4375rem;
      }
    }
  }
}
