#user-subscriptions {
  .subscribe-toggler {
    border-radius: .5625rem;

    &:after {
      @apply absolute w-6 h-6 rounded-full bg-stratos left-0;

      content: '';
      top: -.375rem;
    }

    @screen lg {
      &:hover {
        @apply bg-melrose;
      }
    }

    &.active {
      @apply bg-blue-ribbon;

      &:after {
        @apply left-auto right-0;
      }
    }
  }
}
