#map {
  #all-markers {
    width: calc(100% - 1rem);

    .place-marker-mobile-container {
      flex: 0 0 calc(100% - 2.25rem);
    }

    .place-marker-mobile-text {
      width: calc(100% - 2.5rem);
    }
  }

  .filter-icon svg {
    @apply inline-flex -mt-1 mr-1;
  }

  .active-filter {
    @apply bg-livid-brown border-livid-brown text-white;

    svg {
      @apply text-white;
    }
  }

  .map-container {
    height: 37.5rem;
  }

  .project-map-marker {
    &::after {
      @apply absolute w-0 h-0 z-10;

      content: "";
      top: -9px;
      left: -6px;
      border-top: solid 8px currentColor;
      border-left: solid 7px transparent;
      border-right: solid 7px transparent;
      border-bottom: solid 8px transparent;
    }

    .project-marker-container {
      margin-top: -3.3125rem;
      left: -50%;
    }
  }

  .place-map-marker {
    .place-icon {
      svg {
        @apply w-5 h-5 m-auto;
      }

      &.mobile-touched {
        @apply bg-livid-brown;

        svg {
          @apply text-white;
        }
      }
    }

    .place-icon:not(.invisible):hover + .place-marker-container {
      @media (min-width: theme('screens.lg')) {
        @apply visible w-full top-9 border-0 mt-0 p-0 bg-transparent shadow-none;

        .place-inner-icon {
          @apply hidden;
        }

        &::after {
          @apply hidden;
        }

        .marker-label {
          @apply border-2 border-white rounded p-2 mx-auto bg-white shadow-xl;

          &::after {
            @apply absolute w-0 h-0;

            content: "";
            left: calc(50% - 0.5rem);
            top: calc(-50% + 0.25rem);
            border-top: solid 8px transparent;
            border-left: solid 7px transparent;
            border-right: solid 7px transparent;
            border-bottom: solid 8px $white;
          }
        }
      }
    }

    .place-marker-container {
      top: 15%;
      left: -50%;
      border-radius: 3rem;

      &::after {
        @apply absolute w-0 h-0 z-20;

        content: "";
        left: calc(50% - 0.5rem);
        top: calc(-50% + 0.25rem);
        border-top: solid 8px transparent;
        border-left: solid 7px transparent;
        border-right: solid 7px transparent;
        border-bottom: solid 8px $lividBrown;
      }
    }
  }
}
