#support-section {
  h1 {
    font-size: 2.125rem;

    @screen md {
      @apply text-5xl;
    }
  }

  .links-block {
    @screen md {
      max-width: 52.4375rem;
    }

    .links {
      a {
        width: calc(50% - .75rem);

        @screen md {
          width: calc(25% - 1.125rem);
        }
      }
    }
  }
}
