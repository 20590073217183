.paginator {
  & > div {
    @apply flex flex-row items-center;
  }

  .paginator-page {
    @apply btn-white rounded-sm text-base cursor-pointer px-3 py-2 leading-6;

    margin-right: .375rem;

    &:hover {
      @apply text-black-100 bg-ebb border border-ebb;
    }

    &.current {
      @apply text-white bg-livid-brown border border-livid-brown;
    }

    &.paginator-nav {
      @apply p-0 border-none;

      width: 1.1875rem;
      height: 1.1875rem;
      line-height: 1.1875rem;
      background: #fff url(../../assets/images/v2/icons/arrow_right.svg);
      background-size: 100%;

      &:hover {
        @apply border-none;

        background: #fff url(../../assets/images/v2/icons/arrow_right.svg);
        background-size: 100%;
      }

      &.paginator-prev {
        @apply transform rotate-180;
      }
    }
  }

  .paginator-dots {
    @apply inline-block w-6 mr-1 bg-no-repeat bg-center;

    background-image: url(../../assets/images/icons/ellipsis_blank.svg);

    @screen sm {
      @apply w-10;
    }
  }
}
