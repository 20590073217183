#navigation {
  min-height: 4.5rem;

  .navigation-container {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }

  .overlay {
    background: rgba(0, 0, 0, 0.6);
  }

  .menu-open {
    @apply bg-livid-brown;

    svg {
      @apply text-white transform rotate-180;
    }
  }

  .notifications-toggle {
    &.active {
      @apply bg-livid-brown text-white;
    }

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }


  .notifications-overlay {
    @apply h-screen;

    padding-top: 73px;

    @screen md {
      @apply h-auto pt-0;

      top: 55px;
    }

    .notifications-container {
      @screen md {
        max-height: calc(100vh - 55px)
      }
    }

    .project-card-content.md-horizontal .image-block {
      height: 14.125rem;

      @screen md {
        width: 12.125rem;
        height: 9.25rem;
      }
    }
  }

  .menu-overlay {
    @screen lg {
      top: 52px;
    }

    .profile-menu {
      background: $white;

      hr {
        border-color: $mercury;
      }
    }
  }
}

#search-modal {
  top: 73px;

  &.expanded {
    height: calc(100% - 73px);
    top: 73px;

    .search-modal-content {
      @apply h-full md:h-auto overflow-y-scroll md:overflow-auto;
    }
  }
}
