@supports (--css: variables) {
  .multirange-box {
    @apply pt-3 pb-2 w-full relative;

    height: 3.125rem;

    .multirange-middle-container {
      @apply relative bg-ebb;

      top: -.625rem;

      .multirange-middle {
        @apply relative bg-blue-ribbon h-1 rounded-sm;

        margin-bottom: 1.125rem;
      }
    }

    .multirange-sides {
      height: .625rem;
      border-radius: .1875rem;
    }

    h4 {
      margin-bottom: 1.875rem;
    }

    @supports (-moz-appearance:none) {
      h4 {
        @apply mb-5;
      }

      .range-values-box {
        margin-top: .625rem;
      }
    }
  }

  .range-values-box {
    .range-values-block {
      span {
        padding-top: .125rem;
      }
    }

    .range-value {
      input {
        width: 8rem;
      }
    }
  }

  input[type="range"].multirange {
    @apply p-0 m-0 inline-block align-top w-full;

    margin-top: -2.2rem;

    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
		background: transparent;
  }

    input[type="range"].multirange::-moz-range-thumb {
      transform: scale(1); /* FF doesn't apply position it seems */
    }

    input[type="range"].multirange::-moz-range-track {
      border-color: transparent;
    }

    input[type=range].multirange:focus {
      @apply outline-none;
    }

  input[type="range"].multirange.original {
		@apply absolute w-full z-10;
  }

    // Thumbs for chrome
    input[type="range"].multirange.original::-webkit-slider-thumb,
    input[type="range"].multirange.ghost::-webkit-slider-thumb {
			@apply relative bg-stratos cursor-pointer z-10 w-6 h-6 rounded-full;

      -webkit-appearance: none;
      margin-top: -0.5625rem;
    }

    // Thumbs for mozilla
    input[type="range"].multirange.original::-moz-range-thumb,
    input[type="range"].multirange.ghost::-moz-range-thumb {
			@apply z-10 cursor-pointer bg-stratos w-6 h-6 rounded-full;

      margin-top: -0.5625rem;
    }

  input[type="range"].multirange.ghost {
    @apply relative;
  }

    input[type="range"].multirange.ghost::-webkit-slider-runnable-track {
			@apply w-full cursor-pointer;

      height: .625rem;
      border-radius: .1875rem;
    }

    input[type="range"].multirange.ghost::-moz-range-track {
			@apply w-full cursor-pointer;

			height: .625rem;
      border-radius: .1875rem;
    }

  @supports (-moz-transform: translate(0, 0)) {
    input[type="range"].multirange.original,
    input[type="range"].multirange.ghost {
      height: .625rem;
    }
  }
}

/* IE */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

  input[type="range"].multirange {
		@apply p-0 align-top;
  }

    input[type="range"].multirange::-ms-track {
			@apply w-full cursor-pointer border-0;

			background: transparent;
			color: transparent;
      height: .125rem;
      border-radius: .1875rem;
    }

    input[type="range"].multirange::-ms-thumb {
			@apply h-5 w-5 bg-background-10 cursor-pointer;

      border-radius: 50%;
    }

  input[type="range"].multirange.original {
    @apply absolute;
  }

    input[type="range"].multirange.original::-ms-track {
    }

  input[type="range"].multirange.ghost {
    @apply relative;
  }

  /* Switch colors when inputs are switched, see JavaScript */
  input[type="range"].multirange.switched.original::-ms-fill-lower,
	input[type="range"].multirange.switched.original::-ms-fill-upper,
	input[type="range"].multirange.switched.ghost::-ms-fill-upper,
	input[type="range"].multirange.switched.ghost::-ms-fill-lower,
	input[type="range"].multirange.ghost::-ms-fill-upper,
	input[type="range"].multirange.original::-ms-fill-lower,
	input[type="range"].multirange::-ms-fill-lower,
	input[type="range"].multirange::-ms-fill-upper {
    background: transparent;
  }
}
