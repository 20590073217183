#property-modal {
  .property-images {
    height: 100vw;

    @screen md {
      @apply h-auto;
    }

    .carousel {
      .flickity-viewport {
        width: 100% !important;
        height: 100% !important;

        .flickity-slider {
          @apply w-full h-full;
        }
      }

      .carousel-cell {
        @apply w-full h-full flex items-center justify-center;

        padding: 0.25rem 3.75rem 0.25rem;
        cursor: zoom-in;

        @screen md {
          @apply px-20;
        }

        img {
          @apply max-w-full max-h-full;
        }

        &.placeholder {
          @apply cursor-auto;

          img {
            @apply opacity-75;

            filter: blur(6px);
            -webkit-filter: blur(6px);
          }

          span {
            @apply absolute z-10 text-center font-roobert text-2xl;

            top: 50%;
            left: 3.75rem;
            right: 3.75rem;
          }
        }
      }

      .flickity-button {
        @apply hidden absolute h-full top-0 w-8;

        @screen md {
          @apply block w-12;
        }

        svg {
          @apply w-12 h-12 rounded-sm border border-cold-turkey transform rotate-180;

          padding: .875rem;
        }

        &.previous {
          @apply left-6;
        }

        &.next {
          @apply right-6;
        }

        &:disabled {
          svg {
            @apply opacity-50;
          }
        }
      }

      &.without-pagination {
        .flickity-button {
          @apply hidden;
        }

        .carousel-cell {
          @apply px-1;
        }
      }
    }
  }

  .is-fullscreen {
    @apply pb-0;

    .property-images {
      @apply h-screen;

      @screen md {
        @apply h-full;
      }

      .modal-close {
        @apply block;
      }

      .carousel {
        .carousel-cell {
          cursor: zoom-out;
        }
      }

      .carousel-pagination {
        @apply bottom-24;
      }
    }
  }

  .full-size {
    max-height: 56.25rem;
  }
}
