#last-projects {
  .swiper {
    max-width: 90rem;
    height: 24rem;
  }
}

#main-projects {
  height: 30.625rem;

  @screen md {
    height: 38.25rem;
  }

  .project-card-content {
    @media (min-width: 1440px) {
      width: 87rem;
    }

    .project-title {
      @apply text-white;
    }

    .project-card-content-gradient {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.08) 53.65%, rgba(0, 0, 0, 0.8) 100%);
    }
  }

  .divider {
    &:before {
      @apply absolute left-0 bg-white w-1 h-1;

      content: '';
      top: .525rem;
      border-radius: 50%;

      @screen md {
        top: .725rem;
      }
    }
  }

  .favourite-button {
    padding: .375rem;
  }

  .next-slide-button {
    border-radius: 50%;
  }

  .project-title {
    @screen md {
      font-size: 3.25rem;
    }
  }

  @screen lg {
    .main-project .navigation-button:hover {
      .next-slide-label {
        @apply border-b border-white;
      }

      .next-slide-button {
        @apply border-blue-ribbon;
      }
    }
  }
}

#info-section {
  .info-section-description {
    padding: .5625rem 1.5rem 2rem;

    @screen md {
      padding: 9.3125rem 6rem;
    }
  }
}

#logged-info-section {
  max-width: 90rem;
  min-height: 29.625rem;

  .info-section-description {
    max-height: 4.125rem;

    @screen md {
      max-height: 4.5rem;
    }
  }

  .image-block {
    height: 16.125rem;

    @screen md {
      @apply h-auto;
    }
  }
}

#logos-section {
  .sj-logo {
    background-image: url(../../assets/images/icons/sj_logo.svg);
    width: 60px;
    height: 60px;
  }

  .skanska-logo {
    background-image: url(../../assets/images/icons/skanska_logo.svg);
    width: 91px;
    height: 13px;
  }

  .dnb-logo {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../assets/images/icons/dnb_logo.svg);
    width: 142px;
    height: 40px;
  }

  .heimdal-logo {
    background-image: url(../../assets/images/icons/heimdal_logo.svg);
    width: 60px;
    height: 61px;
  }

  .selvaag-logo {
    background-image: url(../../assets/images/icons/selvaag_logo.svg);
    width: 91px;
    height: 14px;
  }

  .selvaag-logo {
    background-image: url(../../assets/images/icons/selvaag_logo.svg);
    width: 91px;
    height: 14px;
  }

  .solon-eiendom-logo {
    background-image: url(../../assets/images/icons/solon_eiendom_logo.svg);
    width: 91px;
    height: 38px;
  }

  .axer-logo {
    background-image: url(../../assets/images/pictures/axer_logo.png);
    width: 86px;
    height: 45px;
  }

  .rco-logo {
    background-image: url(../../assets/images/icons/rco_logo.svg);
    width: 59px;
    height: 75px;
  }

  .garanti-logo {
    background-image: url(../../assets/images/icons/garanti_logo.svg);
    width: 91px;
    height: 63px;
  }
}
