#customer-modal {
  .customer {
    @apply h-full;

    &.complete {
      @apply h-auto mx-4;
    }

    @screen md {
      @apply h-auto;

      width: 45rem;

      &.complete {
        width: 31.25rem;
      }
    }
  }
}
