#collaborators-page {
  .company {
    @screen lg {
      width: calc(50% - .5rem);
      height: 14.125rem;

      &:nth-of-type(even) {
        @apply mr-0;
      }
    }

    .collab-logo {
      height: 5.625rem;
    }

    .projects-logo {
      height: 11.4375rem;

      @screen md {
        width: 18.375rem;
        height: 11.125rem;
      }

      .right-side {
        &.two {
          & > div {
            @apply h-full;
          }
        }

        &.three {
          & > div {
            height: calc(50% - 6px);

            &:nth-of-type(1) {
              @apply mb-3;
            }
          }
        }
      }
    }
  }
}

#collaborator-page {
  #company-project-logo, #company-project-background {
    height: 17.625rem;

    @screen md {
      height: 22.5rem;
    }

    h1 {
      font-size: 2.125rem;

      @screen md {
        @apply text-5xl;
      }
    }

    .company-logo {
      max-width: 20rem;
      box-shadow: 0 .75rem 1.5rem rgba(0, 0, 0, 0.1);
    }
  }

  #company-project-logo {
    & > div {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.08) 53.65%, rgba(0, 0, 0, 0.8) 100%);
    }
  }

  #show-all-projects {
    @screen md {
      margin-left: -5.25rem;
    }
  }

  #learn-more-link {
    #learn-more {
      padding-bottom: .125rem;

      &:after {
        @apply absolute bottom-0 left-0 w-full bg-black-100;

        content: '';
        height: 1px;
      }
    }

    &:hover {
      #learn-more {
        @apply text-science-blue;

        &:after {
          @apply bg-science-blue;
        }
      }
    }
  }
}
