@font-face {
  font-family: 'Roobert-Medium';
  font-style: normal;
  font-weight: 500;
  src: url(../../assets/fonts/Roobert-Medium.otf);
}

@font-face {
  font-family: 'Roobert-Regular';
  font-style: normal;
  font-weight: 400;
  src: url(../../assets/fonts/Roobert-Regular.otf);
}
