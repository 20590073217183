#save-search-modal {
  .first-search {
    @screen md {
      width: 36.125rem;
    }
  }

  #building-types {
    & > div {
      width: calc((100% - 12px) / 2);
      height: 5.625rem;

      @screen md {
        width: calc((100% - 24px) / 3);
        height: 9rem;
      }

      &.selected {
        @apply bg-livid-brown border-livid-brown text-white;
      }
    }
  }

  .selected-template {
    border-radius: 1.25rem;
  }

  #location {
    .location-input-m-t {
      margin-top: 3.96875rem;

      @screen md {
        margin-top: 3.78125rem;
      }
    }
    .map-h {
      height: 16.9375rem;

      @screen md {
        height: 15.8125rem;
      }
    }
  }
}
