@font-face {
  font-family: 'UntitledSans-Medium';
  font-style: normal;
  font-weight: 500;
  src: url(../../assets/fonts/UntitledSans-Medium.otf);
}

@font-face {
  font-family: 'UntitledSans';
  src: url(../../assets/fonts/UntitledSans-Medium.otf) format('opentype');
  font-weight: 500;
  font-style: bold;
}

@font-face {
  font-family: 'UntitledSans-Regular';
  font-style: normal;
  font-weight: 400;
  src: url(../../assets/fonts/UntitledSans-Regular.otf);
}

@font-face {
  font-family: 'UntitledSans';
  src: url(../../assets/fonts/UntitledSans-Regular.otf) format('opentype');
  font-weight: 400;
  font-style: normal;
}
