// Colors
$black: theme('colors.black.100');
$white: theme('colors.background.10');
$softPeach: #f9f3f3;
$ebb: #efeaea;
$doveGray: #616161;
$scienceBlue: #013dd2;
$coldTurkey: #cec0c0;
$blueRibbon: #014afe;
$mercury: #e6e6e6;
$alabaster: #f9f9f9;
$silver: #cacaca;
$gray: #888888;
$stratos: #01164b;
$lividBrown: #472a31;
$thunderbird: #cf3819;
