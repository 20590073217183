body.premium-landing-page {
  #navigation {
    @apply md:w-screen absolute w-full border-b border-mercury bg-transparent;

    .logo svg path {
      fill: white;
    }

    .navigation-container {
      @apply px-4 xl:px-6;
    }

    .icons,
    .right-icons {
      @apply text-white;

      .text-link {
        @apply betterhover:hover:border-white;
      }
    }

    .notifications-toggle,
    .menu-toggle {
      @apply bg-dark-gray;
    }

    .menu-toggle-name {
      @apply bg-dove-gray;
    }
  }

  #search-modal {
    .search-modal-content {
      @apply max-w-none;

      #search-autocomplete-form {
        @screen 2xl {
          @apply max-w-8xl mx-auto;
        }
      }
    }
  }

  #landing-page-header {
    height: 38rem;

    @screen md {
      height: 37.5rem;
    }

    .image-overlay {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 36%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    }
  }

  #landing-page-brokers-section {
    .min-h-6 {
      min-height: 1.5rem;
    }

    .broker-profile-image {
      width: 18rem;
      height: 18rem;

      @screen xl {
        width: 21.375rem;
        height: 21.375rem;
      }
    }
  }

  #landing-page-gallery-section {
    .left-images img {
      &:nth-of-type(1) {
        height: 12.6875rem;

        @screen md {
          height: 22.375rem;
        }
      }

      &:nth-of-type(2) {
        height: 26.125rem;

        @screen md {
          height: 47.1875rem;
        }
      }
    }

    .right-images img {
      &:nth-of-type(1) {
        height: 26.125rem;

        @screen md {
          height: 47.1875rem;
        }
      }

      &:nth-of-type(2) {
        height: 16rem;

        @screen md {
          height: 28.5625rem;
        }
      }
    }
  }

  #project-description-container {
    .shown-less {
      max-height: 15rem;
    }

    .toggle-show-more-less {
      &.shown-less {
        &:after {
          background: none;
        }
      }
    }
  }

  #units-list {
    table tr {
      @apply bg-white;

      &.bg-mercury {
        @apply bg-white opacity-50;
      }
    }
  }

  #units-list,
  #project-map-section {
    @apply max-w-none;
  }

  #project-developer {
    #project-developer-logo {
      @apply object-contain;

      min-width: 19.8125rem;
    }
  }

  .underline-offset-4 {
    text-underline-offset: 4px;
  }

  #units-list {
    #property-picker-container {
      @apply mx-auto w-full;

      @screen md {
        @apply pb-10;

        max-width: 84rem;
      }
    }
  }
}
