#profile-favourites-page {
  #no-favourites {
    .md-h-section {
      @screen md {
        height: 37rem;
      }
    }

    .w-text-column {
      width: 37.125rem;
    }

    .w-main-img {
      width: 48.5rem;
    }

    .h-main-img {
      height: 29.5rem;
    }

    #no-description {
      max-width: 34rem;
    }
  }
}

#profile-page {
  .subscribe-toggler {
    border-radius: .5625rem;

    &:after {
      @apply absolute w-6 h-6 rounded-full bg-stratos left-0;

      content: '';
      top: -.375rem;
    }

    @screen lg {
      &:hover {
        @apply bg-melrose;
      }
    }

    &.active {
      @apply bg-blue-ribbon;

      &:after {
        @apply left-auto right-0;
      }
    }
  }

  #profile-summary {
    box-shadow: 0 .75rem 1.5rem rgba(0, 0, 0, 0.1);
    width: 19.875rem;

    .profile-image {
      width: 3.125rem;
      height: 3.125rem;
    }
  }

  #profile-navigation-mobile {
    box-shadow: 0 .75rem 1.5rem rgba(0, 0, 0, 0.1);
  }
}

#saved-searches {
  #no-saved-searches {
    .md-h-section {
      @screen md {
        height: 37rem;
      }
    }
  }
}

#no-results {
  .md-h-section {
    @screen md {
      height: 37rem;
    }
  }
  .md-w-text-column {
    @screen md {
      width: 37.125rem;
    }
  }
  .w-main-img {
    width: 48.5rem;
  }
  .h-main-img {
    height: 29.5rem;
  }
}

.md-h-search-card {
  @screen md {
    height: 17.5rem;
  }
}

#saved-searches {
  .search-card-no-images {
    img {
      height: 11.25rem;

      @screen md {
        @apply h-72;

        width: 30.625rem;
      }
    }
  }

  .search-card-images {
    height: 9.75rem;

    @screen md {
      width: 31.25rem;
      height: 13.5rem;
    }

    .right-side {
      &.two {
        & > div {
          @apply h-full;
        }
      }

      &.three {
        & > div {
          height: calc(50% - 6px);

          &:nth-of-type(1) {
            @apply mb-3;
          }
        }
      }
    }
  }
}
