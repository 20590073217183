.toggle-show-more-less {
  &.shown-less {
    @apply overflow-hidden relative h-40;

    &:after {
      @apply absolute bottom-0 left-0 w-full h-16;

      content: "";
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 1) 100% );
    }
  }

  &.shown-more {
    @apply h-auto overflow-auto
  }
}
